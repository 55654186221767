import React from 'react'
import { Box } from 'grommet'
import { tagger } from '../utils'
import { ConnectorLayout, ConnectorList } from '../components'
 
const ConnectorTagsTemplate = ({ pageContext, location }) => {
  const { destinations, bi, connectorPages, tag, tags } = pageContext

  const connectors = connectorPages
    .filter(item => item.product.tags.length > 0 && item.product.tags.includes(tag.title))
    .map(item => item)

  const connectorTags = tags && tagger(tags.map(i => i.title), `/connectors/type/`)

  return (
    <ConnectorLayout 
      location={location} 
      title={`${tag.title} ETL Connectors`}
      titleTemplate="Hippobyte"
      tags={connectorTags}
      pageHero={{
        section: { 
          margin: { vertical: "0", horizontal: "11vw" },
        },
        main: {
          heading: {
            label: `${tag.title} Connectors`,
          },
          subHeading: {
            label: `Hippobyte's automated ETL ${tag.title} data connectors allow you to instantly analyze your data in with your tools of choice — or use SQL for modeling and ad-hoc analysis.`
          },
          style: { flex: "0 1 100%" },
        }
      }}
    >
      <Box fill="horizontal">
        <ConnectorList
          title={`Supported ${tag.title} Source Connectors`}
          description={`Fully managed ${tag.title} connectors save you time and operational costs. Hippobyte dependably synchronizes your ${tag.title} data to a centralized data warehouse with no maintenance overhead.`}
          connectors={connectors}
          pathPrefix={[".", "connectors"]}
        />
        <ConnectorList
          title={`Deliver ${tag.title} data to a supported Destination Connector`}
          description="Centralize your data. Improve data governance. Scale and mature business analytics."
          connectors={destinations}
          pathPrefix={[".", "warehouse"]}
        />
        <ConnectorList
          title={`Visualize and analyze your ${tag.title} data with tools you love.`}
          description="Hippobyte just works. All your existing tools. Nothing new to learn or implement."
          connectors={bi}
          pathPrefix={[".", "bi-tools"]}
        />
      </Box>
    </ConnectorLayout>
  )
}

export default ConnectorTagsTemplate